import { configureStore } from "@reduxjs/toolkit";
import { productSlice, articleSlice, canjeSlice, clienteSlice } from "./";

export const store = configureStore({
  reducer: {
    product: productSlice.reducer,
    article: articleSlice.reducer,
    canje: canjeSlice.reducer,
    cliente: clienteSlice.reducer,
  },
});
