import axios from "axios";
import generarFacturaJson from "../helpers/generarFacturaJson";

const crear = async cliente => {
  const formattedNic = localStorage.getItem("formattedNic");
  const token = localStorage.getItem("token");

  const clienteDatos = {
    RazonSocial: cliente.razonSocial,
    NumeroDocumento: cliente.numeroDocumento,
    IdCondicionVenta: cliente.idCondicionVenta,
    IdCategoriaIVA: cliente.idCategoriaIVA,
    IdSectorComercializacion: cliente.idSectorComercializacion,
    IdTipoDocumento: cliente.idTipoDocumento,
    IdLocalidad: cliente.idLocalidad,
    ListaPrecio: cliente.listaPrecio,
    Calle: cliente.calle,
    Puerta: cliente.puerta,
    CodigoPostal: cliente.codigoPostal,
    IdEmpleadoVendedor: cliente.idEmpleadoVendedor,
  };

  const userData = {
    nic: formattedNic,
    token: token,
    datos: clienteDatos,
    serialNumberKeyClover: localStorage.getItem("serialNumberKeyClover"),
  };

  try {
    const response = await axios.post(`https://facturador-proxy.azurewebsites.net/api/Clientes/CrearCliente`, userData);

    return response.data;
  } catch (error) {
    throw error;
  }
};

const Cliente = {
  crear,
};

export default Cliente;
