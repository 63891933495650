import React, { useState, useEffect } from "react";
import { Box, TextField, Paper, Button, Tooltip, IconButton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import MenuSection from "../components/MenuSection";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

const FormChoferSinCliente = () => {
  const [nombreChofer, setNombreChofer] = useState("");
  const [numeroVehiculo, setNumeroVehiculo] = useState("");
  const [patente, setPatente] = useState("");
  const [kilometraje, setKilometraje] = useState("");
  const [validations, setValidations] = useState({
    exigeDatosVehiculo: false,
    exigeChofer: false,
    exigePatente: false,
    exigeVehiculo: false,
    exigeKm: false,
  });

  const navigate = useNavigate();
  const theme = useTheme();

  useEffect(() => {
    const clienteData = localStorage.getItem("cliente");
    if (clienteData) {
      const parsedClienteData = JSON.parse(clienteData);
      setValidations({
        exigeDatosVehiculo: parsedClienteData.exigeDatosVehiculo || false,
        exigeChofer: parsedClienteData.exigeChofer || false,
        exigePatente: parsedClienteData.exigePatente || false,
        exigeVehiculo: parsedClienteData.exigeVehiculo || false,
        exigeKm: parsedClienteData.exigeKm || false,
      });
    }
  }, []);

  const handleCancelar = () => {
    localStorage.removeItem("chofer");
    navigate("/dashboard");
  };

  const handleAceptar = () => {
    const choferData = JSON.parse(localStorage.getItem("chofer")) || {};

    const updatedChoferData = {
      ...choferData,
      nombreChofer: nombreChofer.toUpperCase(),
      numeroVehiculo: numeroVehiculo.toUpperCase(),
      patente: patente.toUpperCase(),
      kilometraje,
    };
    localStorage.setItem("chofer", JSON.stringify(updatedChoferData));
    localStorage.setItem("completoDatosChofer", true);
    console.log(updatedChoferData);
    navigate("/dashboard");
  };

  // Validación: verificar si se completaron los campos requeridos
  const isButtonDisabled = () => {
    if (validations.exigeDatosVehiculo) {
      return (
        (validations.exigeChofer && !nombreChofer.trim()) ||
        (validations.exigeVehiculo && !numeroVehiculo.trim()) ||
        (validations.exigePatente && !patente.trim()) ||
        (validations.exigeKm && !kilometraje.trim())
      );
    }
    return false; // Si no se exige datos del vehículo, habilitar el botón
  };

  return (
    <>
      <MenuSection />

      <Box
        sx={{
          position: "absolute",
          width: window.innerWidth < 700 ? "90%" : "350px",
          top: window.innerWidth < 700 ? "50%" : "54%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          backgroundColor: "white",
          p: 4,
          borderRadius: "8px",
          height: window.innerWidth < 700 ? "80vh" : "auto",
        }}
      >
        <h3 style={{ color: theme.bandera.colorSecundario, marginBottom: "40px" }}>Ingrese los datos solicitados</h3>
        <Paper
          sx={{
            height: "auto",
            boxShadow: 0,
            marginBottom: 2,
          }}
        >
          <TextField
            label="Nombre del chofer"
            value={nombreChofer}
            onChange={e => setNombreChofer(e.target.value)}
            variant="outlined"
            fullWidth
            sx={{ mb: 2 }}
            InputLabelProps={{
              style: { fontSize: "0.85rem", color: "gray" },
            }}
            error={validations.exigeChofer && !nombreChofer.trim()}
            InputProps={{
              endAdornment: validations.exigeChofer && !nombreChofer.trim() && (
                <Tooltip title="Este campo es obligatorio">
                  <IconButton edge="end" size="small">
                    <ErrorOutlineIcon color="error" />
                  </IconButton>
                </Tooltip>
              ),
            }}
          />
          <TextField
            label="N° vehículo"
            value={numeroVehiculo}
            onChange={e => setNumeroVehiculo(e.target.value)}
            variant="outlined"
            fullWidth
            sx={{ mb: 2 }}
            InputLabelProps={{
              style: { fontSize: "0.85rem", color: "gray" },
            }}
            error={validations.exigeVehiculo && !numeroVehiculo.trim()}
            InputProps={{
              endAdornment: validations.exigeVehiculo && !numeroVehiculo.trim() && (
                <Tooltip title="Este campo es obligatorio">
                  <IconButton edge="end" size="small">
                    <ErrorOutlineIcon color="error" />
                  </IconButton>
                </Tooltip>
              ),
            }}
          />
          <TextField
            label="Patente"
            value={patente}
            onChange={e => setPatente(e.target.value)}
            variant="outlined"
            fullWidth
            sx={{ mb: 2 }}
            InputLabelProps={{
              style: { fontSize: "0.85rem", color: "gray" },
            }}
            inputProps={{ maxLength: 10 }}
            error={validations.exigePatente && !patente.trim()}
            InputProps={{
              endAdornment: validations.exigePatente && !patente.trim() && (
                <Tooltip title="Este campo es obligatorio">
                  <IconButton edge="end" size="small">
                    <ErrorOutlineIcon color="error" />
                  </IconButton>
                </Tooltip>
              ),
            }}
          />
          <TextField
            label="Kilometraje"
            value={kilometraje}
            onChange={e => setKilometraje(e.target.value)}
            variant="outlined"
            fullWidth
            sx={{ mb: 2 }}
            InputLabelProps={{
              style: { fontSize: "0.85rem", color: "gray" },
            }}
            type="number"
            error={validations.exigeKm && !kilometraje.trim()}
            InputProps={{
              endAdornment: validations.exigeKm && !kilometraje.trim() && (
                <Tooltip title="Este campo es obligatorio">
                  <IconButton edge="end" size="small">
                    <ErrorOutlineIcon color="error" />
                  </IconButton>
                </Tooltip>
              ),
            }}
          />
        </Paper>

        <Box
          sx={{
            display: "flex",
            justifyContent: window.innerWidth < 700 ? "center" : "flex-end",
            width: "100%",
            boxSizing: "border-box",
            marginTop: "50px",
          }}
        >
          <Button
            variant="contained"
            sx={{
              boxShadow: 0,
              fontWeight: "bold",
              fontSize: 17,
              backgroundColor: theme.bandera.colorSecundario,
              ":hover": {
                backgroundColor: theme.bandera.colorSecundario,
              },
            }}
            onClick={handleAceptar}
            disabled={isButtonDisabled()}
          >
            ACEPTAR
          </Button>
          <Button
            variant="contained"
            sx={{
              boxShadow: 0,
              fontWeight: "bold",
              marginLeft: "20px",
              fontSize: 17,
              backgroundColor: theme.bandera.colorSecundario,
              ":hover": {
                backgroundColor: theme.bandera.colorSecundario,
              },
            }}
            onClick={handleCancelar}
          >
            CANCELAR
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default FormChoferSinCliente;
