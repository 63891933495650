import React, { useState, useEffect } from "react";
import MenuSection from "../components/MenuSection";
import { useTheme } from "@mui/material/styles";
import {
  Box,
  TableRow,
  TableCell,
  TableBody,
  CircularProgress,
  Table,
  TableHead,
  Typography,
  TableContainer,
  Paper,
  Modal,
  Fade,
  Backdrop,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

const ListadoQRPage = () => {
  const qrArray = JSON.parse(localStorage.getItem("qrsArray"));
  const nombreCaja = localStorage.getItem("nombreCaja");
  const [isLoading, setIsLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedPosition, setSelectedPosition] = useState(null);
  const theme = useTheme();
  const navigate = useNavigate();
  const filterQRsByCaja = qrArray => {
    if (!qrArray || !nombreCaja) return [];
    let range;

    if (nombreCaja.includes("PLAYA")) {
      range = [1, 99];
    } else if (nombreCaja.includes("SHOP")) {
      range = [100, 199];
    } else if (nombreCaja.includes("LUBRICANTES")) {
      range = [200, 299];
    } else if (nombreCaja.includes("GNC")) {
      range = [300, 399];
    } else {
      return qrArray;
    }

    return qrArray.filter(qr => qr.posi >= range[0] && qr.posi <= range[1]);
  };

  const filteredQRs = filterQRsByCaja(qrArray);

  const handleCellClick = qr => {
    setSelectedPosition(qr.posi + 1);
    localStorage.setItem("idPosicionQR", qr.posi);
    localStorage.setItem("urlPosicionQR", qr.url);
    navigate("/pagoqrpage");
    setModalOpen(true);
  };

  const renderRows = () => {
    const rows = [];
    for (let i = 0; i < filteredQRs.length; i += 3) {
      rows.push(
        <TableRow key={i}>
          {filteredQRs.slice(i, i + 3).map((qr, index) => (
            <TableCell
              key={index}
              align="center"
              onClick={() => handleCellClick(qr)}
              style={{ cursor: "pointer" }}
              sx={{ "&:hover": { backgroundColor: theme.palette.action.hover } }}
            >
              <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <img src={qr.url} alt={`QR Code ${qr.posi}`} width="80" height="80" />
              </Box>
              <Typography>{qr.posi}</Typography>
            </TableCell>
          ))}
          {filteredQRs.length % 3 !== 0 &&
            i + 3 >= filteredQRs.length &&
            Array(3 - (filteredQRs.length % 3))
              .fill()
              .map((_, idx) => <TableCell key={`empty-${idx}`} align="center" sx={{ backgroundColor: "transparent" }} />)}
        </TableRow>,
      );
    }
    return rows;
  };

  return (
    <>
      <MenuSection />
      {isLoading ? (
        <TableBody>
          <TableRow>
            <TableCell colSpan={5} align="center" sx={{ borderBottom: "none" }}>
              <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", paddingTop: "8rem" }}>
                <CircularProgress sx={{ color: theme.bandera.colorSecundario }} />
              </Box>
            </TableCell>
          </TableRow>
        </TableBody>
      ) : (
        <Box sx={{ height: "calc(100vh - 110px)", width: "100%" }}>
          <TableContainer component={Paper} sx={{ height: "103%" }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell
                    align="center"
                    colSpan={3}
                    sx={{
                      backgroundColor: theme.bandera.colorSecundario,
                      color: "white",
                      fontWeight: "bold",
                      borderBottom: "2px solid #fff",
                      padding: "8px",
                    }}
                  >
                    LISTADO DE QRs
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>{renderRows()}</TableBody>
            </Table>
          </TableContainer>
        </Box>
      )}
      <Modal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={modalOpen}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 300,
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 4,
              outline: "none",
              textAlign: "center",
              borderRadius: "10px",
            }}
          >
            <Typography variant="h6" component="h2">
              Posición seleccionada
            </Typography>
            <Typography sx={{ mt: 2 }}>{selectedPosition}</Typography>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default ListadoQRPage;
