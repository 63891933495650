import React, { useState, useEffect } from "react";
import { Box, Typography, List, ListItem, ListItemText, Divider, CircularProgress, Button } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useParams, useNavigate } from "react-router-dom";
import MenuSection from "../components/MenuSection";
import SaldoCliente from "../api/getSaldoCliente";
import apiErrorHandler from "../helpers/apiErrorHandler"; // Importa el helper

const SaldosCtacte = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { idCliente, nombreCliente } = useParams();
  const [saldos, setSaldos] = useState([]);
  const [saldoCuentaCorriente, setSaldoCuentaCorriente] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchSaldos = async () => {
      if (idCliente) {
        try {
          const data = await SaldoCliente.getSaldo(idCliente);

          const formattedSaldos = [
            { label: "Cheques no acreditados", value: data.chequesTerceros },
            { label: "Remitos no facturados", value: data.remitosNoFacturados },
            {
              label: "Pedidos no remitidos y no facturados",
              value: data.pedidosNoRemitidosYNoFacturados,
            },
            { label: "Límite crédito", value: data.limiteCredito },
            { label: "Límite restante", value: data.limiteRestante },
          ];

          setSaldos(formattedSaldos);
          setSaldoCuentaCorriente(data.saldoCtaCte);
        } catch (error) {
          console.error("Error al obtener los saldos:", error);
          apiErrorHandler(error, { stopFlow: true });
        } finally {
          setLoading(false);
        }
      }
    };

    fetchSaldos();
  }, [idCliente]);

  return (
    <>
      <MenuSection />

      {loading ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
            backgroundColor: "white",
          }}
        >
          <CircularProgress size={48} sx={{ color: theme.bandera.colorSecundario, mb: 2 }} />
          <Typography variant="body1" sx={{ fontWeight: "bold" }}>
            Cargando datos del Cliente...
          </Typography>
        </Box>
      ) : (
        <Box
          sx={{
            width: "100%",
            maxWidth: "400px",
            margin: "0 auto",
            backgroundColor: "white",
            p: 3,
          }}
        >
          {nombreCliente && (
            <Box sx={{ mb: 1 }}>
              <Typography variant="h6" sx={{ fontWeight: "bold", textAlign: "center", mb: 2 }}>
                {decodeURIComponent(nombreCliente)}
              </Typography>
              <Box
                sx={{
                  backgroundColor: "#f9f9f9",
                  pt: 1,
                  mb: 1,
                  textAlign: "center",
                }}
              >
                <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                  Saldo en Cuenta Corriente
                </Typography>
                {saldoCuentaCorriente !== null ? (
                  <Typography
                    variant="h5"
                    sx={{
                      color: saldoCuentaCorriente < 0 ? "red" : "green",
                      fontWeight: "bold",
                    }}
                  >
                    ${saldoCuentaCorriente.toLocaleString("es-AR", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                  </Typography>
                ) : null}
              </Box>
            </Box>
          )}

          <List>
            {saldos.map((saldo, index) => (
              <React.Fragment key={index}>
                <ListItem
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    padding: "8px 0",
                  }}
                >
                  <ListItemText
                    primary={saldo.label}
                    primaryTypographyProps={{
                      fontSize: "0.9rem",
                      fontWeight: "bold",
                      marginBottom: "4px",
                    }}
                  />
                  <Typography
                    variant="body2"
                    sx={{
                      fontWeight: "bold",
                      color: saldo.value < 0 ? "red" : "green",
                    }}
                  >
                    ${saldo.value.toLocaleString("es-AR", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                  </Typography>
                </ListItem>
                {index < saldos.length - 1 && <Divider />}
              </React.Fragment>
            ))}
          </List>

          <Box sx={{ textAlign: "right" }}>
            <Button
              variant="contained"
              sx={{
                backgroundColor: theme.bandera.colorSecundario,
                color: "white",
                "&:hover": {
                  backgroundColor: theme.bandera.colorSecundario,
                  opacity: 0.9,
                },
              }}
              onClick={() => navigate("/dashboard")}
            >
              Volver
            </Button>
          </Box>
        </Box>
      )}
    </>
  );
};

export default SaldosCtacte;
