import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  cuit: null,
  razonSocial: null,
  codigoPostal: null,
  calle: null,
  numero: null,
  localidad: null,
  catIva: null,
  condVenta: null,
};

export const clienteSlice = createSlice({
  name: "cliente",
  initialState,
  reducers: {
    setCliente: (state, action) => {
      return { ...state, ...action.payload };
    },
    clearCliente: () => initialState,
    updateCliente: (state, action) => {
      const { key, value } = action.payload;
      state[key] = value;
    },
  },
});

export const { setCliente, clearCliente, updateCliente } = clienteSlice.actions;
