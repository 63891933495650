import axios from "axios";

const getLocalidad = async (pageNumber, searchString) => {
  const formattedNic = localStorage.getItem("formattedNic");
  const token = localStorage.getItem("token");

  const userData = {
    nic: formattedNic,
    token: token,
    serialNumberKeyClover: localStorage.getItem("serialNumberKeyClover"),
    pageNumber: pageNumber,
    searchString: searchString,
  };
  try {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/Helper/GetLocalidades`, userData);

    return response;
  } catch (error) {
    throw error;
  }
};

const localidad = {
  getLocalidad,
};

export default localidad;
